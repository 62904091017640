import React from "react";
import ProductNoQr from "./ProductNoQr";

export default function CustomSilder({ data }) {
  console.log(data);
  return (
    <>
      {data &&
        data.map((e) => (
          <ProductNoQr
            key={e?.id}
            shoeImg={e?.image}
            title={e?.title}
            model={e?.model}
            style={e?.style}
            scanned=""
            sold=""
            category={e?.category}
            conversionRate=""
          />
        ))}
    </>
  );
}
