import React, { useRef, useState, useEffect } from "react";
import Printer from "../assets/printer.svg";
import Button from "../components/general/Button";
import { StoreId } from "../Constants";
import { db } from "../firebase/index";
import { collection, doc, getDoc, onSnapshot } from "firebase/firestore";
import "../components/css/ProductCardNoQR.scoped.css";
import ReactToPrint from "react-to-print";
import PrinterDark from "../assets/printerdark.svg";
import ReactQrCode from "qrcode.react";

export default function PrintQr({ dark }) {
  const componentRef = useRef();
  const [data, setData] = useState([]);
  const [logo, setLogo] = useState("");

  function trim(text) {
    if (text.length > 40) {
      return text.substring(0, 20) + "...";
    } else {
      return text;
    }
  }

  useEffect(() => {
    (async () => {
      let docRef = doc(db, "RetailStores", StoreId, "Customization", "QrSettings");
      let docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const storeListener = onSnapshot(docRef, async (snapShot) => {
          let data = snapShot.data();
          setLogo(data.imgUrl);
        });

        return () => {
          storeListener();
        };
      }
    })();
  }, []);

  useEffect(() => {
    const inventoryListener = onSnapshot(
      collection(db, "RetailStores", StoreId, "Products"),
      async (snapShot) => {
        let invData = snapShot.docs.map((e) => {
          return {
            ...e.data(),
            id: e.id,
          };
        });
        setData(invData);
        // const productRefs = invData.map((data) => data.style.path);
        // const productData = await batchFetch(productRefs);
        // updateData(invData.map((e, i) => ({ ...e, ...productData[i] })));
      }
    );

    return () => {
      inventoryListener();
    };
  }, []);

  return (
    <div
      style={{
        position: "absolute",
        width: "100%",
        maxWidth: "95%",
        margin: "auto",
        left: "50%",
        transform: "translateX(-50%)",
        paddingTop: "3rem",
        paddingBottom: "3rem",
      }}
    >
      <ReactToPrint
        bodyClass="qrprint3"
        trigger={() => (
          <Button
            customClass="mx-auto"
            imgSrc={<img src={dark ? PrinterDark : Printer} alt="printer" />}
            text={"Print All"}
          />
        )}
        content={() => componentRef.current}
      />

      <div className="mt-20">
        <div
          ref={componentRef}
          style={{
            textAlign: "center",
          }}
        >
          {data?.map((prod, i) => (
            <div
              key={i}
              style={{
                display: "inline-block",
                maxWidth: "12.5rem",
                width: "100%",
                textAlign: "center",
                verticalAlign: "top",
                marginBottom: ".5rem",
                // padding: "0 1rem",
              }}
            >
              <div
                style={{
                  display: "block",
                  width: "max-content",
                  margin: "auto",
                  marginBottom: ".5rem",
                }}
              >
                <div>
                  <ReactQrCode
                    imageSettings={{
                      src: logo,
                      height: logo ? 22 : 0,
                      width: logo ? 22 : 0,
                      excavate: logo,
                    }}
                    value={`https://qrcode.ingenium.biz/retail/${StoreId}/${
                      prod.id
                    }`}
                    size={140}
                    bgColor={"#ffffff"}
                    fgColor={"#000000"}
                    renderAs="svg"
                    level="H"
                    includeMargin={false}
                  />
                </div>
              </div>
              <div
                style={{
                  fontSize: ".825rem",
                  marginTop: "1rem",
                  textAlign: "center",
                  margin: "auto",
                  display: "box",
                }}
              >
                {trim(prod.title)}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
