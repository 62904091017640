import React, { useState } from "react";
import "../css/Topbar.scoped.css";
import Buttons from "./Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import down from "../../assets/down.svg";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import DownIconDark from "../../assets/downdark.svg";
import { makeStyles } from "@mui/styles";

const Topbar = ({ pagename, links, user, onClick, active, dark }) => {
  console.log(user);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const isOwner = localStorage.getItem("owner");

  let history = useNavigate();

  const useStyles = makeStyles((theme) => ({
    menuPaper: {
      backgroundColor: dark ? "#353C53" : "#fff",
      color: !dark ? "#353C53" : "#fff",
    },
  }));

  const classes = useStyles();

  return (
    <div className="topbar px">
      <div className="pagename">{pagename}</div>
      {links?.length > 0 ? (
        <div className="sm-links">
          {links.map((e, i) => (
            <div
              className={`sm-link ${active === i ? "active-link" : ""}`}
              key={i}
              onClick={() => onClick(i)}
            >
              {e.name}
            </div>
          ))}
        </div>
      ) : null}
      <div className="user">
        {user?.userImg ? (
          <img src={user?.userImg} className="userimg" alt="userimg" />
        ) : (
          <span className="circle" />
        )}
        <div className="username">Welcome Back, {user?.userName}</div>
        <>
          <Button
            variant="raised"
            style={{
              backgroundColor: "transparent",
              padding: "0",
              minWidth: "auto",
              marginLeft: "1rem",
            }}
            id="basic-button"
            aria-controls="basic-menu"
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <img src={!dark ? down : DownIconDark} alt="down" />
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            classes={{ paper: classes.menuPaper }}
          >
            <MenuItem
              className="logoutbtn"
              onClick={() => {
                handleClose();
                history("/logout");
              }}
            >
              Log Out
            </MenuItem>
          </Menu>
        </>
      </div>
    </div>
  );
};

export default React.memo(Topbar);
