import { useEffect, useState } from "react";
import "../css/ProductInfo.scoped.css";
import { doc, onSnapshot } from "firebase/firestore";
import { StoreId } from "../../Constants";
import { db } from "../../firebase/index";
const Categories = () => {
  const [data, setData] = useState();

  useEffect(() => {
    const inventoryListener = onSnapshot(
      doc(db, "RetailStores", StoreId),
      async (snapShot) => {
        setData({
          ...snapShot.data(),
          id: snapShot.id,
        });

        // const storeData = snapShot.docs.map((e) => {
        //   return {
        //     ...e.data(),
        //     id: e.id,
        //   };
        // });
        // setData(storeData);
      }
    );
    return () => {
      inventoryListener();
    };
  }, []);

  return (
    <div className="categories home-categories">
      <div>
        <div className="twogrid-section al-ce jc-sp mt-15">
          <div className="flex al-ce">
            <div className="query bold">Store Name :</div>
            <div className="value">{data?.name}</div>
          </div>
        </div>
        <div className="flex al-ce mt-15">
          <div className="query bold">Owner :</div>
          <div className={`value`}>{data?.fullName}</div>
        </div>
        <div className="flex al-ce mt-15">
          <div className="query bold">Country :</div>
          <div className={`value`}>{data?.country}</div>
        </div>
        <div className="flex al-ce mt-15">
          <div className="query bold">Email :</div>
          <div className={`value`}>{data?.email}</div>
        </div>
        <div className="flex al-ce mt-15">
          <div className="query bold">Date Joined:</div>
          <div className={`value`}>
            {new Date(data?.dateJoined)
              .toDateString()
              .split(" ")
              .slice(1, 4)
              .join(" ")}
          </div>
        </div>
      </div>

      <div className="categories-section mt-18">
        <div className="bold text-center">Categories:</div>
        <div className="categories-grid">
          <center>
            <div className="smbox flex al-ce jc-ce light-green">retail</div>
          </center>
        </div>
      </div>
    </div>
  );
};

export default Categories;
