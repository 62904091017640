import { collection, onSnapshot } from "firebase/firestore";
import _ from "lodash";
import { useEffect, useState } from "react";
import { searchAllInventory } from "../algolia/index";
import Printer from "../assets/printer.svg";
import Buttons from "../components/general/Button";
import SearchBtn from "../components/general/SearchBtn";
import Topbar from "../components/general/Topbar";
import ProductCardQR from "../components/product/ProductCard";
import { StoreId } from "../Constants";
import { db } from "../firebase/index";
import batchFetch from "../firebase/util/batchFetch";
import "./css/ProductLabels.scoped.css";
import { CSVLink } from "react-csv";
import { searchProducts } from "../algolia/products";
import PrinterDark from "../assets/printerdark.svg";
import Container from "../components/qrSetting/Container";
import Analytics from "../components/product/Analytics";
import download from "../assets/import.svg";
import downloadDark from "../assets/importdark.svg";
import SelectLabels from "../components/general/SmallSelect";

const ProductLabels = ({ user, dark }) => {
  const [active, setActive] = useState(0);
  const [qrData, updateQrData] = useState([]);
  const [search, setSearch] = useState("");
  const [time, setTime] = useState("");
  const [searchString, updateSearchString] = useState("");
  const [searchString2, updateSearchString2] = useState("");
  const [searchString3, updateSearchString3] = useState("");
  const [data, updateData] = useState();
  const [soldData, setSoldData] = useState([]);
  const [prodData, setProdData] = useState();

  useEffect(() => {
    const inventoryListener = onSnapshot(
      collection(db, "RetailStores", StoreId, "Products"),
      async (snapShot) => {
        let conData = {};
        snapShot.docs.map((e) => (conData[e.id] = { ...e.data(), id: e.id }));
        setProdData(conData);
      }
    );
    return () => {
      inventoryListener();
    };
  }, []);

  useEffect(() => {
    const inventoryListener = onSnapshot(
      collection(db, "RetailStores", StoreId, "SoldItems"),
      async (snapShot) => {
        const conData = snapShot.docs.map((e) => {
          console.log(prodData);
          return {
            ...e.data(),
            id: e.id,
            stocks: prodData?.[e.data().sku]?.stocks,
            totalSold: prodData?.[e.data().sku]?.totalSold,
          };
        });
        setSoldData(conData);
      }
    );
    return () => {
      inventoryListener();
    };
  }, [prodData]);

  useEffect(() => {
    setSearch("");
  }, [active]);

  useEffect(() => {
    const inventoryListener = onSnapshot(
      collection(db, "RetailStores", StoreId, "Products"),
      async (snapShot) => {
        let invData = [];
        if (searchString2 == "") {
          invData = snapShot.docs.map((e) => {
            return {
              ...e.data(),
              id: e.id,
            };
          });
        } else {
          let hits = await searchProducts(searchString2);

          invData = hits.map((e) => ({
            ...e,
            id: e.objectID.split("/")[1],
          }));

          snapShot.docChanges().forEach((change) => {
            const changedDoc = change.doc.data();
            const id = change.doc.id;

            if (change.type === "removed") {
              //let us remove from INVdata if this exists
              _.remove(invData, (k) => k.id === id);
            }

            if (change.type === "modified") {
              const exists = _.findIndex(invData, (k) => k.id === id);
              if (exists !== -1) {
                //This element was modified
                invData[exists] = {
                  ...changedDoc,
                  id: id,
                };
              }
            }
          });
        }
        updateQrData(invData);
        // const productRefs = invData.map((data) => data.style.path);
        // const productData = await batchFetch(productRefs);
        // updateData(invData.map((e, i) => ({ ...e, ...productData[i] })));
      }
    );

    return () => {
      inventoryListener();
    };
  }, [searchString2]);

  useEffect(() => {
    const inventoryListener = onSnapshot(
      collection(db, "RetailStores", StoreId, "Inventory"),
      async (snapShot) => {
        let invData = [];
        if (searchString == "") {
          invData = snapShot.docs.map((e) => {
            return {
              ...e.data(),
              id: e.id,
            };
          });
        } else {
          let hits = await searchAllInventory(searchString);

          invData = hits.map((e) => ({
            ...e,
            id: e.objectID.split("/")[1],
          }));

          snapShot.docChanges().forEach((change) => {
            const changedDoc = change.doc.data();
            const id = change.doc.id;

            if (change.type === "removed") {
              //let us remove from INVdata if this exists
              _.remove(invData, (k) => k.id === id);
            }

            if (change.type === "modified") {
              const exists = _.findIndex(invData, (k) => k.id === id);
              if (exists !== -1) {
                //This element was modified
                invData[exists] = {
                  ...changedDoc,
                  id: id,
                };
              }
            }
          });
        }

        const productRefs = invData.map((data) =>
          data.style.path ? data.style.path : data.style
        );

        const productData = await batchFetch(productRefs);
        updateData(invData.map((e, i) => ({ ...e, ...productData[i] })));
      }
    );

    return () => {
      inventoryListener();
    };
  }, [searchString]);

  return (
    <div className="page">
      <Topbar
        dark={dark}
        pagename={"Product Labels"}
        links={[
          {
            name: "Analytics",
          },
          {
            name: "QR Code",
          },
          {
            name: "QR Code Settings",
          },
        ]}
        active={active}
        onClick={setActive}
        user={user}
      />
      <div className="add-btns px mb-10">
        {active === 0 && (
          <div className="mr-14">
            <SelectLabels
              dark={dark}
              name={"time"}
              value={time}
              updateValue={(e) => {
                setTime(e.target.value);
              }}
              placeholderText={"Choose Currency"}
              options={[
                {
                  label: "Daily",
                  value: "Daily",
                },
                {
                  label: "Weekly",
                  value: "Weekly",
                },
                {
                  label: "Monthly",
                  value: "Monthly",
                },
                {
                  label: "Yearly",
                  value: "Yearly",
                },
              ]}
            />
          </div>
        )}
        {active !== 2 && (
          <div className="mr-14">
            <SearchBtn
              dark={dark}
              value={search}
              updateValue={setSearch}
              onEnter={async () => {
                if (active === 0) {
                  updateSearchString(search);
                }
                if (active === 1) {
                  updateSearchString2(search);
                }
                if (active === 3) {
                  updateSearchString3();
                }
              }}
            />
          </div>
        )}
        {active === 0 && (
          <div className="mr-14">
            <CSVLink 
                filename={`sales-data.csv`} 
                data={[
                  [
                    "Category",
                    "Img URL",
                    "Title",
                    "SKU",
                    "Style",
                    "Size",
                    "Condition",
                    "Last Sale Price",
                    "Markup", 
                    "Purchase Date",
                    "Purchase Price", 
                    "Sold Date",
                    "Sold Price",
                    "Profits",
                  ],
                  ...soldData?.map((e) => [
                    e.category,
                    e.image,
                    e.title,
                    e.sku,
                    e.style,
                    e.size,
                    e.condition,
                    e.lastSalePrice,
                    e.markup,
                    (new Date(e.purchaseDate)).toLocaleDateString(),
                    e.purchasePrice.toFixed(2),
                    (new Date(e.soldDate)).toLocaleDateString(),
                    e.soldPrice.toFixed(2),
                    (e.soldPrice - e.purchasePrice).toFixed(2),
                  ]),
                ]}
              >
            <Buttons
              text={"Downlaod"}
              imgSrc={<img src={dark ? downloadDark : download} alt="" />}
            />
            </CSVLink>
          </div>
        )}

        {active === 1 && (
          <Buttons
            imgSrc={<img src={dark ? PrinterDark : Printer} alt="printer" />}
            text={"Print All"}
            onClick={() =>
              active === 0
                ? window.open("/print", "_blank")
                : window.open("/printqr", "_blank")
            }
          />
        )}
      </div>

      {active === 0 && <Analytics data={soldData} />}

      {active === 1 && (
        <div className="productCardsWrapper px">
          {qrData.map((prod, i) => (
            <ProductCardQR
              key={i}
              category={prod?.category}
              shoeImg={prod?.image}
              title={prod?.title}
              model={prod?.style}
              number={prod?.sku}
              QRimg={""}
              dark={dark}
              stocks={prod?.stocks}
            />
          ))}
        </div>
      )}

      {active === 2 && (
        <div className="content px">
          <div className="box receipt-container">
            <div className="headersection flex al-ce jc-sp pt-0">
              <div>QR Code Settings</div>
            </div>
            <Container dark={dark} />
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductLabels;
