import "../css/Sidebar.scoped.css";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.png";
import { useLocation } from "react-router-dom";

const Sidebar = ({ dark }) => {
  let location = useLocation();

  let Links = [
    {
      to: "/product",
      name: "Product Labels",
      svg: (
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.83447 13.7601C1.80099 13.2686 1.39833 12.8793 0.905678 12.8793C0.385396 12.8793 -0.0325008 13.315 0.00199242 13.8341C0.39146 19.6958 2.30418 21.6085 8.16583 21.998C8.68497 22.0325 9.12072 21.6146 9.12072 21.0943C9.12072 20.6017 8.73136 20.199 8.23985 20.1655C7.28991 20.1008 6.48456 19.9952 5.79822 19.8426C4.55511 19.5663 3.81763 19.1613 3.32818 18.6718C2.83873 18.1824 2.43365 17.4449 2.15738 16.2018C2.00484 15.5154 1.89919 14.7101 1.83447 13.7601Z"
            className="active-white"
            fill={`${!dark ? "#ABAFD1" : "#5A5D74"}`}
          />
          <path
            d="M20.0868 13.7601C20.1203 13.2686 20.5229 12.8793 21.0156 12.8793C21.5359 12.8793 21.9538 13.315 21.9193 13.8342C21.5298 19.6958 19.6171 21.6085 13.7554 21.998C13.2363 22.0325 12.8005 21.6146 12.8005 21.0943C12.8005 20.6017 13.1899 20.199 13.6814 20.1655C14.6313 20.1008 15.4367 19.9952 16.123 19.8426C17.3661 19.5663 18.1036 19.1613 18.5931 18.6718C19.0825 18.1824 19.4876 17.4449 19.7639 16.2018C19.9164 15.5154 20.0221 14.7101 20.0868 13.7601Z"
            className="active-white"
            fill={`${!dark ? "#ABAFD1" : "#5A5D74"}`}
          />
          <path
            d="M22 11.0815C22 11.0675 22 11.0534 22 11.0394C22 11.0253 22 11.0113 22 10.9973V11.0815Z"
            className="active-white"
            fill={`${!dark ? "#ABAFD1" : "#5A5D74"}`}
          />
          <path
            d="M21.9193 8.24459C21.9538 8.76373 21.5359 9.19948 21.0156 9.19948C20.5229 9.19948 20.1203 8.81012 20.0868 8.31861C20.0221 7.36866 19.9164 6.5633 19.7639 5.87697C19.4876 4.63385 19.0825 3.89637 18.5931 3.40692C18.1036 2.91747 17.3661 2.51239 16.123 2.23612C15.4367 2.08358 14.6313 1.97793 13.6814 1.91321C13.1899 1.87973 12.8005 1.47706 12.8005 0.984417C12.8005 0.464135 13.2363 0.0462391 13.7554 0.0807319C19.6171 0.470198 21.5298 2.38292 21.9193 8.24459Z"
            className="active-white"
            fill={`${!dark ? "#ABAFD1" : "#5A5D74"}`}
          />
          <path
            d="M1.83447 8.31861C1.80099 8.81012 1.39833 9.19948 0.905678 9.19948C0.385396 9.19948 -0.0325008 8.76373 0.00199232 8.24459C0.39146 2.38293 2.30418 0.470204 8.16584 0.0807339C8.68497 0.0462405 9.12072 0.464137 9.12072 0.984419C9.12072 1.47707 8.73136 1.87973 8.23985 1.91321C7.28991 1.97793 6.48456 2.08358 5.79822 2.23612C4.55511 2.51239 3.81763 2.91747 3.32818 3.40692C2.83873 3.89637 2.43365 4.63385 2.15738 5.87697C2.00484 6.5633 1.89919 7.36866 1.83447 8.31861Z"
            className="active-white"
            fill={`${!dark ? "#ABAFD1" : "#5A5D74"}`}
          />
          <rect
            x="6.4165"
            y="6.49561"
            width="9.16646"
            height="1.83329"
            rx="0.916646"
            fill="#767BFA"
          />
          <rect
            x="8.25"
            y="13.8286"
            width="5.49988"
            height="1.83329"
            rx="0.916646"
            fill="#767BFA"
          />
          <rect
            x="4.58301"
            y="10.1621"
            width="12.833"
            height="1.83329"
            rx="0.916646"
            fill="#767BFA"
          />
        </svg>
      ),
    },

    {
      to: "/settings",
      name: "Settings",
      svg: (
        <svg
          width="22"
          height="24"
          viewBox="0 0 22 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.60643 3.65554C6.55267 3.94423 6.25616 4.11326 5.98413 4.0171C1.30599 2.36352 -2.16181 8.42001 1.58941 11.6377C1.81102 11.8278 1.81102 12.1722 1.58941 12.3623C-2.16181 15.58 1.30599 21.6365 5.98413 19.9829C6.25616 19.8867 6.55267 20.0558 6.60643 20.3445C7.51399 25.2185 14.486 25.2185 15.3936 20.3445C15.4473 20.0558 15.7438 19.8867 16.0159 19.9829C20.694 21.6365 24.1618 15.58 20.4106 12.3623C20.189 12.1722 20.189 11.8278 20.4106 11.6377C24.1618 8.42001 20.694 2.36352 16.0159 4.0171C15.7438 4.11326 15.4473 3.94423 15.3936 3.65554C14.486 -1.21851 7.51399 -1.21851 6.60643 3.65554ZM8.57055 4.02202C9.07256 1.32599 12.9274 1.32599 13.4295 4.02202C13.7076 5.5159 15.2508 6.40856 16.6811 5.90298C19.2624 4.99056 21.1899 8.33543 19.1106 10.119C17.9584 11.1073 17.9584 12.8927 19.1106 13.881C21.1899 15.6646 19.2624 19.0094 16.6811 18.097C15.2508 17.5914 13.7076 18.4841 13.4295 19.978C12.9274 22.674 9.07256 22.674 8.57055 19.978C8.29239 18.4841 6.74923 17.5914 5.3189 18.097C2.73757 19.0094 0.810125 15.6646 2.88945 13.881C4.04161 12.8927 4.04161 11.1073 2.88945 10.119C0.810125 8.33543 2.73757 4.99056 5.3189 5.90298C6.74923 6.40856 8.29239 5.5159 8.57055 4.02202Z"
            className="active-white"
            fill={`${!dark ? "#ABAFD1" : "#5A5D74"}`}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.00391 12C7.00391 14.2091 8.79293 16 10.9998 16C13.2067 16 14.9957 14.2091 14.9957 12C14.9957 9.79086 13.2067 8 10.9998 8C8.79293 8 7.00391 9.79086 7.00391 12ZM9.00186 12C9.00186 13.1046 9.89637 14 10.9998 14C12.1032 14 12.9978 13.1046 12.9978 12C12.9978 10.8954 12.1032 10 10.9998 10C9.89637 10 9.00186 10.8954 9.00186 12Z"
            fill="#767BFA"
          />
        </svg>
      ),
    },
  ];

  return (
    <div className="sidebar-container">
      <div className="sidebar">
        <div className="logo">
          <img src={logo} alt="" />
        </div>
        {Links.map((link, i) => (
          <Link
            className={`nav-link ${
              location.pathname === link.to ? "active-link" : ""
            }`}
            key={i}
            to={link.to}
          >
            {link.svg}
            {link.name}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Sidebar;
