import React, { useContext, useRef, useState, useEffect } from "react";
import Printer from "../assets/printer.svg";
import Button from "../components/general/Button";
import { CurrencyContext } from "../context/CurrencyContext";
import { StoreId } from "../Constants";
import { db } from "../firebase/index";
import batchFetch from "../firebase/util/batchFetch"; //
import { collection, onSnapshot } from "firebase/firestore";
import "../components/css/ProductCardNoQR.scoped.css";
import ReactToPrint from "react-to-print";
import PrinterDark from "../assets/printerdark.svg";

export default function PrintAllNoQr({ dark }) {
  const {
    rates,
    sizingValues,
    womenSizingValues,
    girlSizingValues,
    toddler,
    young,
  } = useContext(CurrencyContext);
  let localSizing = localStorage.getItem("sizing");
  const componentRef = useRef();
  const [data, setData] = useState([]);

  useEffect(() => {
    const inventoryListener = onSnapshot(
      collection(db, "RetailStores", StoreId, "Inventory"),
      async (snapShot) => {
        let invData = snapShot.docs.map((e) => {
          return {
            ...e.data(),
            id: e.id,
          };
        });

        const productRefs = invData.map((data) =>
          data.style.path ? data.style.path : data.style
        );

        const productData = await batchFetch(productRefs);
        setData(invData.map((e, i) => ({ ...e, ...productData[i] })));
      }
    );

    return () => {
      inventoryListener();
    };
  }, []);

  return (
    <div
      style={{
        position: "absolute",
        width: "100%",
        maxWidth: "400px",
        margin: "auto",
        left: "50%",
        transform: "translateX(-50%)",
        paddingTop: "3rem",
      }}
    >
      <ReactToPrint
        bodyClass="qrprint2"
        trigger={() => (
          <Button
            customClass="mx-auto"
            imgSrc={<img src={dark ? PrinterDark : Printer} alt="printer" />}
            text={"Print All"}
          />
        )}
        content={() => componentRef.current}
      />

      <div ref={componentRef}>
        {data?.map((prod, i) => (
          <div key={i} className="sideDiv mt-20">
            <div className="newstyle">
              <span>Style:</span>
              {prod.sku}
            </div>
            <div className="size">
              <span>Size:</span>
              {sizingValues[localSizing][prod.size]
                ? sizingValues[localSizing][prod.size]
                : womenSizingValues[localSizing][prod.size]
                ? womenSizingValues[localSizing][prod.size]
                : girlSizingValues[localSizing][prod.size]
                ? girlSizingValues[localSizing][prod.size]
                : toddler[localSizing][prod.size]
                ? toddler[localSizing][prod.size]
                : young[localSizing][prod.size]}
            </div>
            <div className="consignment">
              <span>Consignment:</span>
              {prod.consigneeID ? prod.consigneeID : "-"}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
