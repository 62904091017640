import { createTheme, ThemeProvider } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import Sidebar from "./components/general/Sidebar";
import { CurrencyProvider } from "./context/CurrencyContext";
import ProductLabels from "./pages/ProductLabels";
import Settings from "./pages/Settings";
import PrintAllNoQr from "./pages/PrintNoQr";
import PrintQr from "./pages/PrintQr";
import Login from "./pages/Login";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "./firebase";
import { StoreId } from "./Constants";
import LockedImg from "./assets/locked.png";
import Logout from "./pages/Logout";

function App() {
  const [currency, setCurrency] = useState("usd");
  const [language, setLanguage] = useState("english");
  const [mode, setMode] = useState("light");
  const [sizing, setSizing] = useState("us");
  const [loading, setLoading] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [isLocked, setIsLocked] = useState(false);
  const [genStaffSettings, setGenStaffSettings] = useState();
  const [staffSettings, setStaffSettings] = useState();

  const [user, setUser] = useState({
    userName: "",
    userImg: "",
  });

  const isOwner = localStorage.getItem("owner");
  const THEME = createTheme({
    typography: {
      fontFamily: `"Poppins", "Helvetica", "Arial", sans-serif`,
    },
  });

  useEffect(() => {
    if (loggedIn) {
      const storeListener = onSnapshot(
        doc(db, "RetailStores", StoreId),
        async (snapShot) => {
          setIsLocked({
            ...snapShot.data(),
            id: snapShot.id,
          });

          let data = snapShot.data();

          setUser({
            userName: data.fullName,
            userImg: data.userImg,
          });
        }
      );
      return () => {
        storeListener();
      };
    }
  }, [loggedIn]);

  useEffect(() => {
    const loggedInUser = localStorage.getItem("loggedIn");
    if (loggedInUser) {
      const foundUser = JSON.parse(loggedInUser);
      setLoggedIn(foundUser);
    }

    let localCurrency = localStorage.getItem("currency");
    if (!localCurrency) {
      localStorage.setItem("currency", currency);
    } else {
      setCurrency(localCurrency);
    }

    let localLanguage = localStorage.getItem("language");
    if (!localLanguage) {
      localStorage.setItem("language", language);
    } else {
      setLanguage(localLanguage);
    }

    let localSizing = localStorage.getItem("sizing");
    if (!localSizing) {
      localStorage.setItem("sizing", sizing);
    } else {
      setSizing(localSizing);
    }

    let localMode = localStorage.getItem("mode");
    if (!localMode) {
      localStorage.setItem("mode", mode);
    } else {
      setMode(localMode);
    }
  }, []);

  if (loggedIn && isLocked.locked) {
    return (
      <div>
        <img className="lockedimg" src={LockedImg} alt="locked" />
      </div>
    );
  }

  return (
    <CurrencyProvider>
      {loading && (
        <div className="loader-container">
          <div className="lds-ripple">
            <div />
            <div />
          </div>
        </div>
      )}
      <ThemeProvider theme={THEME}>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          theme="colored"
          pauseOnHover
        />
        {loggedIn ? (
          <div className={`App ${mode === "dark" ? "dark" : ""}`}>
            <div className="main-container">
              <Routes>
                <Route path="/login" />
                <Route path="/print" />
                <Route path="/printqr" />
                <Route path="/logout" />

                <Route
                  path="/product"
                  element={<Sidebar dark={mode === "dark"} />}
                />
                <Route
                  path="/settings"
                  element={<Sidebar dark={mode === "dark"} />}
                />
              </Routes>

              <Routes>
                <Route path="*" element={<Navigate to="/product" replace />} />
                <Route
                  path="/login"
                  element={
                    <Login setLoading={setLoading} dark={mode === "dark"} />
                  }
                />

                <Route
                  index
                  path="/product"
                  element={<ProductLabels user={user} dark={mode === "dark"} />}
                />

                <Route
                  path="/print"
                  element={<PrintAllNoQr user={user} dark={mode === "dark"} />}
                />
                <Route
                  path="/printqr"
                  element={<PrintQr user={user} dark={mode === "dark"} />}
                />

                <Route
                  path="/settings"
                  element={
                    <Settings
                      currency={currency}
                      setCurrency={setCurrency}
                      language={language}
                      setLanguage={setLanguage}
                      mode={mode}
                      setMode={setMode}
                      sizing={sizing}
                      setSizing={setSizing}
                      user={user}
                      dark={mode === "dark"}
                    />
                  }
                />
                <Route
                  path="/logout"
                  element={<Logout dark={mode === "dark"} />}
                />
              </Routes>
            </div>
          </div>
        ) : (
          <Login setLoading={setLoading} />
        )}
      </ThemeProvider>
    </CurrencyProvider>
  );
}

export default App;
