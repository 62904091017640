import { useEffect, useState } from "react";
import add from "../assets/add.svg";
import addDark from "../assets/addark.svg";
import Button from "../components/general/Button";
import Input from "../components/general/Input";
import SelectLabels from "../components/general/Select";
import { SuccessToast } from "../components/general/ToastNotification";
import Topbar from "../components/general/Topbar";
import { StoreId } from "../Constants";
import "./css/Settings.scoped.css";

const Settings = ({
  currency,
  setCurrency,
  language,
  setLanguage,
  mode,
  setMode,
  sizing,
  setSizing,
  user,
  dark,
}) => {
  const isOwner = localStorage.getItem("owner");
  const [api, setApi] = useState("");

  useEffect(() => {
    setApi(StoreId);
  }, []);

  return (
    <div>
      <div className="page">
        <Topbar dark={dark} pagename={"Settings"} user={user} />
        <div className="content px">
          <div className="settings-container">
            <div className="flex al-ce jc-sp headersection">
              <div className="smtext">Setting Options</div>
            </div>
            <div className="twogrid">
              <div className="px-25 ">
                <div className="wrap">
                  <div className="select-container">
                    <div className="label">Currency</div>
                    <SelectLabels
                      dark={dark}
                      name={"currency"}
                      value={currency}
                      updateValue={(e) => {
                        setCurrency(e.target.value);
                        localStorage.setItem("currency", e.target.value);
                      }}
                      placeholderText={"Choose Currency"}
                      options={[
                        {
                          label: "CNY",
                          value: "cny",
                        },
                        {
                          label: "EUR",
                          value: "eur",
                        },
                        {
                          label: "SGD",
                          value: "sgd",
                        },
                        {
                          label: "USD",
                          value: "usd",
                        },
                      ]}
                    />
                  </div>
                  <div className="select-container">
                    <div className="label">Language</div>
                    <SelectLabels
                      dark={dark}
                      name={"language"}
                      value={language}
                      updateValue={(e) => {
                        setLanguage(e.target.value);
                        localStorage.setItem("language", e.target.value);
                      }}
                      placeholderText={"Choose Language"}
                      options={[
                        {
                          label: "English",
                          value: "english",
                        },
                      ]}
                    />
                  </div>
                  <div className="select-container">
                    <div className="label">Sizing</div>
                    <SelectLabels
                      dark={dark}
                      name={"sizing"}
                      value={sizing}
                      updateValue={(e) => {
                        setSizing(e.target.value);
                        localStorage.setItem("sizing", e.target.value);
                      }}
                      placeholderText={"EU/UK/US"}
                      options={[
                        {
                          label: "EU",
                          value: "eu",
                        },
                        {
                          label: "UK",
                          value: "uk",
                        },
                        {
                          label: "US",
                          value: "us",
                        },
                      ]}
                    />
                  </div>
                  <div className="select-container">
                    <div className="label">Mode</div>
                    <SelectLabels
                      dark={dark}
                      name={"mode"}
                      value={mode}
                      updateValue={(e) => {
                        setMode(e.target.value);
                        localStorage.setItem("mode", e.target.value);
                      }}
                      placeholderText={"Choose Mode"}
                      options={[
                        {
                          label: "Light",
                          value: "light",
                        },
                        {
                          label: "Dark",
                          value: "dark",
                        },
                      ]}
                    />
                  </div>
                </div>

                <div className="wrap">
                  <div className="flex end btncontainer">
                    <Button
                      text={"Save"}
                      imgSrc={<img src={dark ? addDark : add} alt="add icon" />}
                      onClick={() => SuccessToast("Success", "Settings saved.")}
                    />
                  </div>
                </div>
              </div>
              <div />
            </div>

            <div className="twogrid">
              <div className="px-25 mt-0">
                <div className="wrap">
                  <div className="select-container span-2">
                    <div className="label">API Integration</div>
                    <Input
                      disabled={!isOwner}
                      dark={dark}
                      name={"api"}
                      value={api}
                      updateValue={(e) => {
                        setApi(e.target.value);
                      }}
                      placeholder={"enter API"}
                    />
                  </div>
                </div>

                <div className="wrap">
                  <div className="flex end btncontainer">
                    <Button
                      text={"Save"}
                      imgSrc={<img src={dark ? addDark : add} alt="add icon" />}
                      onClick={() => SuccessToast("Success", "Settings saved.")}
                    />
                  </div>
                </div>
              </div>
              <div />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
