import "../css/Input.scoped.css";

const Input = ({
  dark,
  name,
  value,
  updateValue,
  type,
  placeholder,
  customClass,
  custom,
  readOnly,
  prefix,
}) => {
  return prefix ? (
    <div className="input-field flex al-ce">
      <span className="prefix">{prefix}</span>
      <input
        name={name}
        readOnly={readOnly}
        className={`input-field prefix-input ${customClass ?? ""}`}
        type={type}
        placeholder={placeholder.toString()}
        value={value}
        style={type === "date" ? { color: !value ? "#abafd1" : "#000" } : null}
        onChange={(e) => updateValue(custom ? e.target.value : e)}
      />
    </div>
  ) : (
    <input
      name={name}
      readOnly={readOnly}
      className={`input-field ${customClass ?? ""}`}
      type={type}
      placeholder={placeholder.toString()}
      value={value}
      style={
        type === "time" || type === "date"
          ? dark
            ? { color: !value ? "#5a5d74" : "#f6f7ff" }
            : { color: !value ? "#abafd1" : "#000" }
          : null
      }
      onChange={(e) => updateValue(custom ? e.target.value : e)}
    />
  );
};

export default Input;
