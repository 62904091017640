import "../css/ProductCard.scoped.css";

export default function ProductNoQr({
  shoeImg,
  title,
  model,
  style,
  scanned,
  sold,
  conversionRate,
  category = "Others",
}) {
  return (
    <>
      <div className="productWrapper">
        <div className="productBoxWrapper left">
          <div className="greenBox capitalize">{category}</div>
          <div className="whiteBoxWrap left">
            <img className="shoeImg center" alt="shoe" src={shoeImg} />
            <div className="prodTitle left">{title}</div>
            <div className="model">{style}</div>
            <div className="style">
              <span>Style: </span>
              {model ? model : style}
            </div>
            <div className="style">
              <span>Total Scanned: </span> {scanned}
            </div>
            <div className="style">
              <span>Total Sold: </span> {sold}
            </div>
            <div className="style">
              <span>Conversion Rate: </span> {conversionRate}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
