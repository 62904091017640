import React from "react";
import "../css/Analytics.scoped.css";
import TransactionHistory from "../dashOwner/TransactionHistory";
import Categories from "./Categories";
import CustomSilder from "./CustomSilder";
import ProductCard from "./ProductCard";

export default function Analytics({ data }) {
  return (
    <div className="analytics">
      <div className="px span-2">
        <Categories />
        <div className="transaction-container">
          <TransactionHistory data={data} />
        </div>
      </div>
      <div className="span px">
        <CustomSilder data={data} />
      </div>
    </div>
  );
}
