import { collection, onSnapshot } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import { StoreId } from "../../Constants";
import { CurrencyContext } from "../../context/CurrencyContext";
import { db } from "../../firebase/index";
import "../css/ProductInfo.scoped.css";

const TransactionHistory = ({ data }) => {
  return (
    <div className="transaction-history">
      <div className="flex al-ce jc-sp priceheader">
        <div className="headersection">
          <div className="smtext">Transaction History</div>
        </div>
      </div>
      <div>
        <div className="headersgrid">
          <div />
          <div>Items</div>
          <div>Style</div>
          <div>Size</div>
          <div>Date Sold</div>
          <div className="center">Total Stocks</div>
          <div className="center">Quantity Sold</div>
        </div>
        <div className="items">
          {data?.map((e, i) => (
            <div key={i} className="item">
              <img src={e?.image} alt="item" />
              <div>{e?.title}</div>
              <div>{e?.style}</div>
              <div>{e?.size}</div>
              <div>{e?.soldDate}</div>
              <div className="center">{e?.stocks}</div>
              <div className="center">{e?.totalSold}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TransactionHistory;
